import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header border-0" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = {
  key: 0,
  class: "card-body p-0"
}
const _hoisted_5 = { class: "table border table-rounded table-striped gy-7 gs-7 p-0 m-0" }
const _hoisted_6 = {
  key: 1,
  class: "card-body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.chart.title), 1)
    ]),
    (_ctx.chart.headers.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("table", _hoisted_5, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chart.headers, (header) => {
                  return (_openBlock(), _createElementBlock("th", {
                    style: {"font-size":"11px","font-weight":"bold"},
                    key: header.key
                  }, _toDisplayString(header.name), 1))
                }), 128))
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chart.data, (line) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: line.id
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(line, (item) => {
                    return (_openBlock(), _createElementBlock("td", { key: item }, _toDisplayString(item), 1))
                  }), 128))
                ]))
              }), 128))
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6))
  ]))
}