import {BaseService} from "@/core/services/BaseService";

class ChartService extends BaseService<any> {
  endpoint = "/custom-chart"


  async export(data) {
    return this.http().post(this.endpoint, data, {responseType: "blob"}).then(res => {
      return res;
    })
  }
}

export default new ChartService();