
import {defineComponent, ref, watch} from 'vue'
import ChartService from "@/core/services/ChartService";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import VueHighcharts from "vue3-highcharts/dist/vue3-highcharts.common";
import ChartDataTable from "@/views/chart/ChartDataTable.vue";
import ChartDataTable2 from "@/views/chart/ChartDataTable2.vue";

export default defineComponent({
  name: "Chart",
  components: {ChartDataTable2, ChartDataTable, VueHighcharts, EntityLoading},
  props: {
    params: {}
  },
  setup(props) {
    const loading = ref(true);
    const chart = ref<any>({});
    const loadChart = (request) => {
      loading.value = true;
      ChartService.create(request).then(res => {
        chart.value = res;
      }).finally(() => {
        loading.value = false;
      })
    }

    loadChart(props.params)
    watch(() => props.params, cb => {
      loadChart(cb);
    })
    return {
      chart,
      loading,
    }
  }
})
